<template>
  <a-form ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
    <template v-for="item in formData" :key='item'>
      <div v-if="item.sms_type=='Alibaba'">
        <div class="fz-16 s_c333 m-b-10">{{item.sms_type=='Alibaba'?'阿里云短信平台':'创蓝短信平台'}}<a-button type="link" @click='item.edit = !item.edit'>{{!item.edit?'修改':'取消'}}</a-button></div>
        <a-form-item ref="access_id" label="账号" name="access_id">
          <a-input v-model:value="item.access_id" placeholder='请输入账号' :disabled="!item.edit" />
        </a-form-item>
        <a-form-item ref="access_secret" label="密码" name="access_secret">
          <a-input v-model:value="item.access_secret" placeholder='请输入密码' :disabled="!item.edit" />
        </a-form-item>
        <div class="s_flex_end">
          <a-button type="primary" @click="setConfig(item)" :loading='loading' v-if='item.edit'>修改</a-button>
        </div>
      </div>
    </template>
    <!-- <div class="fz-16 s_c333 m-b-20">启用平台</div>
    <a-radio-group v-model:value="sms_type">
      <template v-for="item in formData" :key='item'>
        <a-radio :value="item.sms_type">{{item.sms_type=='Alibaba'?'阿里云短信平台':'创蓝短信平台'}}</a-radio>
      </template>
    </a-radio-group>
    <div class="s_flex_end m-t-30">
      <a-button type="primary" @click="onSubmit" :loading='loading'>切换</a-button>
    </div> -->
  </a-form>
</template>
<script>
import { ref, onMounted, reactive, toRefs, inject } from 'vue';
import { message } from 'ant-design-vue';
import { $iscode } from "@/utils/app";
import { getMessageConfig, setMessageConfig,switchConfig} from "@/api/message";
export default {
  components: {},
  setup(props,{emit}) {
    const state = reactive({
      loading:false,
      labelCol: { span: 3 },
      wrapperCol: { span: 21 },
      formData:[],
      sms_type:''
    });
    //获取详情
    const getMore = async () => {
      const hide = message.loading("正在加载中...", 0);
      var res = await getMessageConfig().then((res) => res.data);
      if ($iscode(res)) {
        state.formData = res.data
        state.sms_type = res.data.find(v=>v.status).sms_type
      } else {
        message.error(res.msg);
      }
      setTimeout(hide);
    };
    //设置
    const setConfig = async (item) =>{
      state.loading = true;
      const hide = message.loading("正在加载中...", 0);
      var res = await setMessageConfig({...item,id:item.sms_type}).then((res) => res.data);
      if ($iscode(res)) {
        message.success(res.msg);
      } else {
        message.error(res.msg);
      }
      state.loading = false;
      setTimeout(hide);
    }
    //切换
    const onSubmit = async() => {
      state.loading = true;
      const hide = message.loading("正在加载中...", 0);
      var res = await switchConfig({sms_type:state.sms_type}).then((res) => res.data);
      if ($iscode(res)) {
        message.success(res.msg);
        emit('onsubmit')
      } else {
        message.error(res.msg);
      }
      state.loading = false;
      setTimeout(hide);
    }
    onMounted(() => {
      getMore();
    });
    return {
      ...toRefs(state),
      onSubmit,
      setConfig
    }
  }
}
</script>
<style>
</style>