<template>
  <a-layout class='index animated fadeIn'>
    <a-card>
      <SmallSquare content="1、短信推送模板针对渠道进行相关应用的短信提醒服务
      2、启用的推送模板品牌端可以选择是否开启推送服务
      3、禁用的推送模板品牌端无法查看选择" />
      <a-button class="m-b-20 m-t-20" type="primary" v-has:smsTemplateAdd="()=> {$router.push('/brandMsg/smsTemplate/add?type=add')}">新建短信推送模板</a-button>
      <a-button class="m-b-20 m-t-20 m-l-10" type="primary" v-has:smsPlatformSwitch="()=>{showSwitch = true}">短信平台切换</a-button>
      <a-form ref='form' :model='search' :style="{'marginBottom':'20px'}" :initialValues="search"
        :onFinish='pageSearchChange' layout='inline'>
        <a-form-item label="关键词" name='keywords'>
          <a-input v-model:value="search.keywords" style="width:270px" placeholder="请输入模板名称/内容进行搜索"></a-input>
        </a-form-item>
        <a-form-item label="消息应用" name='application'>
          <a-select v-model:value="search.application" :style="{width:'180px'}" placeholder="全部">
            <a-select-option value="">全部</a-select-option>
            <a-select-option :value="1">积分商城</a-select-option>
            <a-select-option :value="2">在线订货</a-select-option>
            <a-select-option :value="3">平台通知</a-select-option>
            <a-select-option :value="4">云店商城</a-select-option>
            <a-select-option :value="5">智能防窜</a-select-option>
            <a-select-option :value="6">智能营销</a-select-option>
            <a-select-option :value="7">资产</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="状态" name='status'>
          <a-select v-model:value="search.status" :style="{width:'180px'}" placeholder="全部">
            <a-select-option value="">全部</a-select-option>
            <a-select-option :value="1">启用</a-select-option>
            <a-select-option :value="0">禁用</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="审核状态" name='audit_status'>
          <a-select v-model:value="search.audit_status" :style="{width:'180px'}" placeholder="全部">
            <a-select-option value="">全部</a-select-option>
            <a-select-option :value="0">未提交审核</a-select-option>
            <a-select-option :value="1">已提交审核</a-select-option>
            <a-select-option :value="2">审核通过</a-select-option>
            <a-select-option :value="3">审核不通过</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" htmlType='submit'>搜索</a-button>
        </a-form-item>
        <a-form-item>
          <a-button v-has:smsTemplateRefresh='examineRefresh' type="primary" ghost>刷新审核状态</a-button>
        </a-form-item>
        <a-form-item>
          <a-button v-has:smsTemplateAllSubmit='allExamine' type="primary" ghost>批量提交审核</a-button>
        </a-form-item>
      </a-form>
      <a-table :pagination='false' :loading='listLoading' bordered rowKey='id' :columns='columns' :data-source='data' class="tableFixBox" :scroll="{ x: 'max-content'}">
        <template v-slot:audit_status_text="{ record }" >
          <p v-if='record.audit_status!=3'>{{record.audit_status_text}}</p>
          <a-tooltip placement="top" v-if='record.audit_status==3'>
            <template #title>
              {{record.reason}}
            </template>
            <a href="javascript:;">{{record.audit_status_text}}</a>
          </a-tooltip>
        </template>
        <template v-slot:action="{ record }">
          <a-dropdown>
            <a class="ant-dropdown-link"> 操作
              <DownOutlined />
            </a>
            <template v-slot:overlay>
              <a-menu>
                <a-menu-item>
                  <a v-has:smsTemplateEdit="()=> {$router.push('/brandMsg/smsTemplate/edit?type=edit&id='+record.id)}" href="javascript:;">编辑</a>
                </a-menu-item>
                <a-menu-item>
                  <a v-has:smsTemplateStatus="()=>statusSms(record)" href="javascript:;">{{record.status ? '禁用':'启用'}}</a>
                </a-menu-item>
                <a-menu-item>
                  <a v-has:smsTemplateDel="()=>delSms(record)" href="javascript:;">删除</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </a-table>
      <a-pagination :style="{marginTop:'20px',textAlign:'right'}" show-size-changer show-quick-jumper
        :current="search.page" :page-size="search.size" :total="total" @change="pageCurrentChange"
        @showSizeChange="pageSizeChange" :show-total="total => `总数: ${total} `" />
    </a-card>
    <a-modal :keyboard="false" title="短信平台切换" centered v-model:visible="showSwitch" :width="500" :footer="null" destroyOnClose>
      <p class="m-b-20 s_c999">注意：首次切换时，原来的短信模板需要提交到切换后的短信平台审核，审核通过后才能正常使用。</p>
      <smsSwitch @onsubmit='showSwitch=false'></smsSwitch>
    </a-modal>
  </a-layout>
</template>
<script>
  import { DownOutlined } from "@ant-design/icons-vue";
  import { ref, onMounted, reactive, toRefs, inject } from "vue";
  import { message, Model } from "ant-design-vue";
  import { getRouter } from "@/utils/app";
  import { setPageState, getPageState } from "@/utils/pageState";
  import { getSmsSysList, smsSysStatus,smsSysDel,smsSysStatusPull,smsSysAllExamine } from "@/api/message";
  import { $iscode } from "@/utils/app";
  import smsSwitch from "./smsSwitch.vue"
  // 初始化默认筛选项数值
  let defSearch = {
    keywords: "",
    application: '',
    page: 1,
    limit: 10,
    status:"",
    audit_status:""
  };
  export default {
    components: { DownOutlined,smsSwitch },
    setup(props) {
      // // 初始化 分页信息和筛选项信息
      let search = ref({ ...defSearch });
      // 列表数据和列头配置
      let data = ref([]);
      const state = reactive({
        listLoading: false,
        total: 1,
        showSwitch:false
      });
      let columns = [
        {
          title: "消息应用",
          dataIndex: "application",
          key: "application",
        },
        {
          title: "模板名称",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "触发条件",
          dataIndex: "trigger_condition",
          key: "trigger_condition",
        },
        {
          title: "模板内容",
          dataIndex: "content",
          key: "content",
        },
        {
          title: "审核状态",
          dataIndex: "audit_status_text",
          key:"audit_status_text",
          slots: { customRender: "audit_status_text" },
        },
        {
          title: "状态",
          dataIndex: "status_text",
          key: "status_text"
        },
        {
          title: "操作",
          key: "action",
          width:120,
          fixed:"right",
          slots: { customRender: "action" },
        },
      ];
      const $Modal = inject('$Modal')
      // 页面筛选项搜索
      const pageSearchChange = () => {
        search.value = { ...search.value, page: 1 };
        initData(search.value);
      };
      // 分页当前页切换
      const pageCurrentChange = (page, pageSize) => {
        search.value.page = page;
        initData(search.value);
      };
      // 分页当前页显示多少条切换
      const pageSizeChange = (current, size) => {
        search.value.page = 1;
        search.value.limit = size;
        initData(search.value);
      };
      //启用禁用
      const statusSms = (e) => {
        $Modal.confirm({
          centered: true,
          title: '提示',
          content: '确定'+(e.status ? '禁用':'启用')+'当前短信模板吗？', onOk: async () => {
            let res = await smsSysStatus(e.id).then(res => res.data);
            if ($iscode(res)) {
              message.success(res.msg);
              initData(search.value);
            } else {
              message.error(res.msg);
            }
          }
        })
      }
      //删除
      const delSms = (e) => {
        $Modal.confirm({
          centered: true,
          title: '提示',
          content: '确定删除当前短信模板吗？', onOk: async () => {
            let res = await smsSysDel(e.id).then(res => res.data);
            if ($iscode(res)) {
              message.success(res.msg);
              initData(search.value);
            } else {
              message.error(res.msg);
            }
          }
        })
      }
      // 刷新状态
      const examineRefresh = async () => {
        const hide = message.loading("正在加载中...", 0);
        var res = await smsSysStatusPull().then((res) => res.data);
        if ($iscode(res)) {
          initData(search.value);
        } else {
          message.error(res.msg);
        }
        setTimeout(hide);
      };
      //批量提交
      const allExamine = async () => {
        const hide = message.loading("正在加载中...", 0);
        var res = await smsSysAllExamine().then((res) => res.data);
        if ($iscode(res)) {
          initData(search.value);
        } else {
          message.error(res.msg);
        }
        setTimeout(hide);
      };
      //获取列表
      const initData = async (values) => {
        data.value = []
        state.listLoading = true;
        try {
          let res = await getSmsSysList(values).then(res => res.data);
          state.listLoading = false;
          if ($iscode(res)) {
            data.value = res.data.data;
            state.total = res.data.total;
          } else {
            message.error(res.msg);
          }
        } catch (e) {
          state.listLoading = false;
        }
      };
      onMounted(() => {
        // 查看是否有留存状态，有则替换
        let pageState = getPageState(getRouter().route.path);
        if (pageState) {
          search.value = Object.assign(search.value, pageState);
        }
        initData(search.value);
      });
      return {
        ...toRefs(state),
        search,
        data,
        columns,
        pageSearchChange,
        pageCurrentChange,
        pageSizeChange,
        delSms,
        statusSms,
        examineRefresh,
        allExamine
      };
    },
  };
</script>